var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-wrapper',[_c('div',{attrs:{"slot":"wrapper"},slot:"wrapper"},[_c('div',{staticClass:"container-header"},[_c('div',{staticClass:"container-tips flex"},[_c('div',{staticClass:"tips-title"},[_vm._v("温馨提示:")]),_c('div',{staticClass:"tips-content"},_vm._l((_vm.tips),function(item,index){return _c('div',{key:index,staticClass:"tips-item"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"container-tag flex"},_vm._l((_vm.tags),function(item,index){return _c('div',{key:index,staticClass:"tag-item",class:_vm.tagsIndex == index ? 'tag-item-active' : '',on:{"click":function($event){return _vm.handleTag(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"container-area"},[_c('div',{staticClass:"home-form"},_vm._l((_vm.forms),function(item,index){return _c('div',{key:index,staticClass:"flex fome-item"},[_c('div',{staticClass:"form-lable"},[_c('i',{class:'icon-' + item.icon}),_vm._v(_vm._s(item.name)+": ")]),(item.type == 1 || index == 2)?_c('div',{staticClass:"form-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ups[item.value]),expression:"ups[item.value]"}],staticClass:"inputs",attrs:{"type":"text","placeholder":`填写${item.name}`},domProps:{"value":(_vm.ups[item.value])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ups, item.value, $event.target.value)}}})]):_vm._e(),(item.select == 1)?_c('div',{staticClass:"form-red"},[_vm._v("【必填】")]):_vm._e(),(item.btnIcon == 'plus')?_c('div',{staticClass:"flex"},[(!_vm.fileName)?_c('span',{staticClass:"form-red"},[_vm._v("未选择论文")]):_vm._e(),(_vm.fileName)?_c('span',{staticClass:"form-red"},[_vm._v(_vm._s(_vm.fileName))]):_vm._e(),_c('div',{staticClass:"form-submit",style:({
                'background-color': item.color,
                'border-color': item.border,
              })},[_c('i',{class:'icon-w-' + item.btnIcon}),_vm._v(" "+_vm._s(item.btn)+" "),_c('input',{ref:"fileClear",refInFor:true,staticClass:"files",attrs:{"type":"file","name":"file","id":"fileupload","accept":".doc,.pdf,.docx"},on:{"change":_vm.changeFile}})])]):_vm._e(),(index == 2 && _vm.links)?_c('div',[(item.type != 1 && item.btnIcon != 'plus')?_c('div',{staticClass:"form-submit",style:({
                'background-color': item.color,
                'border-color': item.border,
              }),on:{"click":function($event){return _vm.handleSubmit(item)}}},[_c('i',{class:'icon-w-' + item.btnIcon}),_vm._v(" "+_vm._s(item.btn)+" ")]):_vm._e()]):_vm._e(),(index != 2)?_c('div',[(item.type != 1 && item.btnIcon != 'plus')?_c('div',{staticClass:"form-submit",style:({
                'background-color': item.color,
                'border-color': item.border,
              }),on:{"click":function($event){return _vm.handleSubmit(item)}}},[_c('i',{class:'icon-w-' + item.btnIcon}),_vm._v(" "+_vm._s(item.btn)+" ")]):_vm._e()]):_vm._e()])}),0),_c('div',{staticClass:"down-footer"},[_c('div',{staticClass:"down-header flex"},[_c('div',{staticClass:"header-icon"},[_c('i',{staticClass:"icon-fullscreen"})]),_c('span',[_vm._v("提交检测须知")])]),_c('div',{staticClass:"footer-content"},_vm._l((_vm.foots),function(item,index){return _c('div',{key:index,staticClass:"foot-item flex"},[_c('div',{staticClass:"item-title",domProps:{"innerHTML":_vm._s(item.name)}}),_c('div',{staticClass:"item-tip",domProps:{"innerHTML":_vm._s(item.tip)}})])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }