<template>
  <page-wrapper>
    <div slot="wrapper">
      <div class="container-header">
        <div class="container-tips flex">
          <div class="tips-title">温馨提示:</div>
          <div class="tips-content">
            <div class="tips-item" v-for="(item, index) in tips" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="container-tag flex">
          <div
            class="tag-item"
            :class="tagsIndex == index ? 'tag-item-active' : ''"
            v-for="(item, index) in tags"
            :key="index"
            @click="handleTag(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="container-area">
        <div class="home-form">
          <div
            class="flex fome-item"
            v-for="(item, index) in forms"
            :key="index"
          >
            <div class="form-lable">
              <i :class="'icon-' + item.icon"></i>{{ item.name }}:
            </div>
            <div class="form-input" v-if="item.type == 1 || index == 2">
              <input
                type="text"
                class="inputs"
                v-model="ups[item.value]"
                :placeholder="`填写${item.name}`"
              />
            </div>
            <div class="form-red" v-if="item.select == 1">【必填】</div>
            <div class="flex" v-if="item.btnIcon == 'plus'">
              <span class="form-red" v-if="!fileName">未选择论文</span>
              <span class="form-red" v-if="fileName">{{ fileName }}</span>
              <!-- <div class="button-del" v-if="fileName" @click="handleDel">
                删除
              </div> -->
              <div
                class="form-submit"
                :style="{
                  'background-color': item.color,
                  'border-color': item.border,
                }"
              >
                <i :class="'icon-w-' + item.btnIcon"></i>
                {{ item.btn }}
                <input
                  type="file"
                  class="files"
                  name="file"
                  @change="changeFile"
                  ref="fileClear"
                  id="fileupload"
                  accept=".doc,.pdf,.docx"
                />
              </div>
            </div>

            <div v-if="index == 2 && links">
              <div
                class="form-submit"
                :style="{
                  'background-color': item.color,
                  'border-color': item.border,
                }"
                v-if="item.type != 1 && item.btnIcon != 'plus'"
                @click="handleSubmit(item)"
              >
                <i :class="'icon-w-' + item.btnIcon"></i>
                {{ item.btn }}
              </div>
            </div>
            <div v-if="index != 2">
              <div
                class="form-submit"
                :style="{
                  'background-color': item.color,
                  'border-color': item.border,
                }"
                v-if="item.type != 1 && item.btnIcon != 'plus'"
                @click="handleSubmit(item)"
              >
                <i :class="'icon-w-' + item.btnIcon"></i>
                {{ item.btn }}
              </div>
            </div>
          </div>
        </div>
        <div class="down-footer">
          <div class="down-header flex">
            <div class="header-icon"><i class="icon-fullscreen"></i></div>
            <span>提交检测须知</span>
          </div>
          <div class="footer-content">
            <div
              class="foot-item flex"
              v-for="(item, index) in foots"
              :key="index"
            >
              <div class="item-title" v-html="item.name"></div>
              <div class="item-tip" v-html="item.tip"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-wrapper>
</template>

<script>
const tips = [
  {
    name:
      " 1、现在处于毕业高峰期，检测的同学们比较多，所以论文检测系统检测所需时间较长，如果您的报告没有及时检测完成，请耐心等候",
  },
  {
    name:
      " 2、提交完待测文章后，可以关闭检测页面，先休息会，过一阵再次访问检测系统，通过检测卡号提取检测结果哦!",
  },
];
const tags = [
  { name: "提交检测", key: "Home" },
  { name: "下载报告", key: "Down" },
];
const foots = [
  {
    name: "<span class='red'>提交后退款</span>",
    tip: "此系统一旦提交，开始检测后，概不退款！",
  },
  {
    name: "<span class='blue'>检测卡号</span>",
    tip: "在购买商品后，客服会发对应的检测卡号，输入正确的检测卡号即可！",
  },
  {
    name: "<span class='red'>文章大小限制</span>",
    tip:
      "Word文档大小请不要超过<span class='red'>15MB</span> ，且字符数不能大于<span class='red'>5w</span>字符，否则将无法上传；请把不必要的图片删除",
  },
  {
    name: "<span class='blue'>检测论文格式</span>",
    tip:
      "上传检测的文档格式为docx,doc,pdf格式，请勿上传其他格式的文档,对于需要识别脚尾注的论文请格式调整为word2003",
  },
  {
    name: "<span class='red'>是否准确</span>",
    tip: "我们是维普正品查重，检测报告支持官网验证，检测结果和官网一致！",
  },
];
const forms = [
  { name: "论文标题", icon: "indent-left", value: "title", type: 1, select: 1 },
  { name: "论文作者", icon: "user", value: "author", type: 1, select: 1 },
  {
    name: "检测卡号",
    icon: "barcode",
    value: "orderInfo",
    type: 2,
    select: 1,
    btn: "去淘宝购买",
    color: "#ECA140",
    btnIcon: "shopping-cart",
    border: "#B2803A",
  },

  {
    name: "待测论文",
    icon: "pager",
    value: "",
    type: 2,
    select: 0,
    btn: "选择文件..",
    color: "#1F4EC5",
    btnIcon: "plus",
    border: "#2960B1",
  },
  {
    name: "提交论文",
    icon: "list-alt",
    value: "",
    type: 3,
    select: 0,
    btn: "提交检测",
    color: "#6BA95F",
    btnIcon: "inbox",
    border: "#67A35B",
  },
];
import { SubmitFile, Test } from "@/utils/http";
function byURLParam(url, props) {
  let u = (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      (a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a
    ),
    {}
  );
  return props ? u[props] : u;
}
export default {
  data() {
    return {
      tips,
      tags,
      tagsIndex: 0,
      foots,
      ups: {},
      forms,
      fileName: "",
      files: null,
      phone: false,
      links: "",
    };
  },
  mounted() {
    this.fileName = "";
    this.hrefInit();
  },
  watch: {
    "$route.path": (val) => {
      console.log(val, "dddd");
      this.hrefInit();
    },
  },
  methods: {
    hrefInit() {
      let href = window.location.href;
      if (href) {
        let isUrl = byURLParam(href);
        let phones = {
          name: "手机号码",
          icon: "phone",
          value: "phone",
          type: 1,
          select: 0,
        };
        if (isUrl.phone) {
          let bools = this.formPhoneFn();
          if (!bools) {
            this.forms.splice(3, 0, phones);
          }
        } else {
          this.forms = [
            {
              name: "论文标题",
              icon: "indent-left",
              value: "title",
              type: 1,
              select: 1,
            },
            {
              name: "论文作者",
              icon: "user",
              value: "author",
              type: 1,
              select: 1,
            },
            {
              name: "检测卡号",
              icon: "barcode",
              value: "orderInfo",
              type: 2,
              select: 1,
              btn: "去淘宝购买",
              color: "#ECA140",
              btnIcon: "shopping-cart",
              border: "#B2803A",
            },

            {
              name: "待测论文",
              icon: "pager",
              value: "",
              type: 2,
              select: 0,
              btn: "选择文件..",
              color: "#1F4EC5",
              btnIcon: "plus",
              border: "#2960B1",
            },
            {
              name: "提交论文",
              icon: "list-alt",
              value: "",
              type: 3,
              select: 0,
              btn: "提交检测",
              color: "#6BA95F",
              btnIcon: "inbox",
              border: "#67A35B",
            },
          ];
        }
        if (isUrl.link) {
          this.links = decodeURIComponent(isUrl.link);
        } else {
          this.links = "";
        }
        console.log(this.links);
        //"https://item.taobao.com/item.htm?id=642188287731"
      }
    },
    formPhoneFn() {
      for (let i in this.forms) {
        let child = this.forms[i];
        if (child.icon == "phone") {
          return true;
        }
      }
      return false;
    },
    handleTag(item) {
      this.$router.push({
        name: item.key,
      });
    },
    changeFile(e) {
      let eFile = e.target.files;
      // console.log(eFile, 333);
      if (eFile) {
        this.files = eFile[0];
        this.fileName = eFile[0].name;
        // console.log(this.fileName);
        // console.log(this.files)
      }
    },
    isFile() {
      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("orderInfo", this.ups.orderInfo);
      formData.append("title", this.ups.title);
      formData.append("author", this.ups.author);
      return formData;
    },
    handleSubmit(item) {
      let type = item.type;
      if (type == 2) {
        if (this.links) {
          window.open(this.links, "_blank");
        }

        return false;
      }

      if (!this.ups.title) {
        return this.msgFn("请输入论文标题");
      }
      if (!this.ups.author) {
        return this.msgFn("请输入论文作者");
      }
      if (!this.ups.orderInfo) {
        return this.msgFn("请输入检测卡号");
      }
      if (!this.files) {
        return this.msgFn("请上传文件");
      }

      let ups = this.isFile();
      var layerIndex = layer.load(1, {
        shade: [0.1, "#000"],
      });
      SubmitFile(ups)
        .then((res) => {
          console.log("res", res);
          this.ups = {};
          this.fileName = "";
          this.files = null;
          layer.closeAll();
          this.msgFn(res.info);
        })
        .catch((err) => {
          layer.closeAll();
          this.msgFn(err.info);
        });
    },
    msgFn(data) {
      layer.alert(data);
    },
    handleDel() {
      this.files = null;
      this.fileName = "";
      this.$refs.fileClear.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.container-tips {
  height: 41px;
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  color: #c09853;
}
.tips-title {
  color: #c09853;
  font-weight: bold;
  margin-right: 10px;
  width: 55px;
  flex-shrink: 0;
}
.tips-item {
  height: 21px;
  line-height: 21px;
  overflow: hidden;
}
.container-tag {
  height: 38px;
  line-height: 38px;
  padding-left: 40px;
  .tag-item {
    height: 36px;
    line-height: 38px;
    width: 94px;
    text-align: center;
    color: #3b741c;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    margin-right: 10px;
    &:hover {
      height: 37px;
      background-color: #eee;
    }
    &.tag-item-active {
      background: #188b08;
      background: -webkit-linear-gradient(to bottom, #50df55 0%, #188b08 55px);
      background: -moz-linear-gradient(to bottom, #50df55 0%, #188b08 55px);
      background: linear-gradient(to bottom, #50df55 0%, #188b08 55px);
      border: 1px solid #109e0a;
      color: white !important;
    }
  }
}
.container-area {
  position: absolute;
  top: 116px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.container-header {
  height: 116px;
  width: 100%;
  border-bottom: 2px solid #109e0a;
}

.down-footer {
  padding: 20px 0;
}
.footer-content {
  padding-top: 20px;
}

.home-form {
  padding: 40px 0 0 45px;
  line-height: 30px;
  .fome-item {
    height: 70px;
  }
}
.form-lable {
  line-height: 30px;
  font-size: 14px;

  font-weight: bold;
  margin-right: 15px;
  i {
    padding-right: 4px;
  }
}
.form-input {
  width: 240px;
  height: 30px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 10px;
  .inputs {
    width: 100%;
    border: 0;
    height: 28px;
    line-height: 28px;
    outline: none;
  }
}
.form-submit {
  margin-left: 15px;
  height: 30px;
  background-color: #c63932;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 130px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  i {
    padding-right: 4px;
    color: #fff;
  }
}
.form-inputs {
  width: 300px;
}
.form-red {
  padding-left: 10px;
  color: #c63932;
  font-weight: 600;
  font-size: 14px;
}
.files {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 130px;
  background: blue;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
.down-header {
  padding: 20px 10px 0 25px;
  height: 38px;
  position: relative;
  overflow: hidden;

  width: 100%;
  border-bottom: 1px dashed #cdcdcd;
  span {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 700;
    line-height: 48px;
  }
  .header-icon {
    padding-top: 15px;
  }
}
.button-del {
  padding: 0 10px;
}
</style>
